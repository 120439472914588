<template>
    <div
        v-if="isShow"
        class="card mb-4"
    >
        <div class="card-header d-flex justify-content-between align-items-center">
            <h5 class="card-title mb-0">
                Message
            </h5>

            <button
                v-if="!view"
                @click="onRemoveClicked"
                class="btn-close"
            ></button>
        </div>

        <div class="card-body">
            <select-form-group-component
                v-if="!view"
                :data="tgUnitTypes"
                :selected="type"
                :name="getSelectName()"
                @on-changed="onSelectChanged"
                label="Select type"
                required
            ></select-form-group-component>

            <TelegramTextMessageComponent
                v-if="isText()"
                :index="index"
                :text="getTgUnitText()"
                :view="view"
            ></TelegramTextMessageComponent>

            <TelegramImageMessageComponent
                v-else-if="isImage()"
                :index="index"
                :image="getTgUnitImage()"
                :image_id="getTgUnitImageId()"
                :id="getTgUnitId()"
                :view="view"
            ></TelegramImageMessageComponent>

            <TelegramTextButtonMessageComponent
                v-else-if="isTextButtons()"
                :index="index"
                :tg-screens="tgScreens"
                :items="getTgUnitDataButtons()"
                :text="getTgUnitText()"
                :remove-buttons="getTgUnitIsRemoveButtons()"
            ></TelegramTextButtonMessageComponent>

            <TelegramFileMessageComponent
                :index="index"
                :id="getTgUnitId()"
                :file="getTgUnitFile()"
                :view="view"
                v-else-if="isFile()"
            ></TelegramFileMessageComponent>

            <TelegramImageTextMessageComponent
                v-else-if="isImageText()"
                :index="index"
                :image="getTgUnitImage()"
                :image_id="getTgUnitImageId()"
                :text="getTgUnitText()"
                :id="getTgUnitId()"
                :view="view"
                :tg-screens="tgScreens"
                :items="getTgUnitDataButtons()"
            ></TelegramImageTextMessageComponent>

            <input
                :name="getTgUnitName()"
                :value="getTgUnitId()"
                type="hidden"
            >
        </div>
    </div>
</template>

<script>
import TelegramTextMessageComponent from './Units/TelegramTextMessageComponent';
import TelegramImageMessageComponent from './Units/TelegramImageMessageComponent';
import TelegramImageTextMessageComponent from './Units/TelegramImageTextMessageComponent';
import TelegramPollMessageComponent from './Units/TelegramPollMessageComponent';
import TelegramTextButtonMessageComponent from './Units/TelegramTextButtonMessageComponent';
import TelegramFileMessageComponent from "./Units/TelegramFileMessageComponent";

const TYPE_TEXT = 1;
const TYPE_IMAGE = 2;
const TYPE_IMAGE_TEXT = 3;
const TYPE_POLL = 4;
const TYPE_TEXT_BUTTONS = 5;
const TYPE_FILE = 6;

export default {
    name: 'TelegramMessageComponent',
    components: {
        TelegramFileMessageComponent,
        TelegramTextButtonMessageComponent,
        TelegramPollMessageComponent,
        TelegramImageTextMessageComponent,
        TelegramImageMessageComponent,
        TelegramTextMessageComponent,
    },
    created() {
        if (
            !this.tgUnit
            || !this.tgUnit.hasOwnProperty('type_id')
        ) {
            const keys = Object.keys(this.tgUnitTypes);
            this.type = parseInt(keys[0]);
        } else {
            this.type = parseInt(this.tgUnit.type_id);
        }
    },
    props: {
        index: {
            type: Number,
            required: true,
        },
        tgUnitTypes: {
            type: Object,
            required: true,
        },
        tgUnit: {
            type: Object,
        },
        view: {
            type: Boolean,
            default: false,
        },
        tgScreens: {
            type: Object,
            default: {},
        },
    },
    data() {
        return {
            isShow: true,
            type: 0,
        };
    },
    methods: {
        onRemoveClicked() {
            this.isShow = false;
        },
        onSelectChanged(value) {
            this.type = parseInt(value);
        },
        isText() {
            return this.type === TYPE_TEXT;
        },
        isImage() {
            return this.type === TYPE_IMAGE;
        },
        isImageText() {
            return this.type === TYPE_IMAGE_TEXT;
        },
        isPoll() {
            return this.type === TYPE_POLL;
        },
        isTextButtons() {
            return this.type === TYPE_TEXT_BUTTONS;
        },
        isFile() {
            return this.type === TYPE_FILE;
        },
        getTypeName() {
            return 'type_' + this.index;
        },
        getTgUnitText() {
            if (this.tgUnit.hasOwnProperty('type_id')) {
                return this.tgUnit.data.text;
            }

            return '';
        },
        getTgUnitImage() {
            if (this.tgUnit.hasOwnProperty('type_id')) {
                return this.tgUnit.string_data_image_panel;
            }

            return '';
        },
        getTgUnitImageId() {
            if (this.tgUnit.hasOwnProperty('type_id')) {
                return this.tgUnit.data.image;
            }

            return '';
        },
        getTgUnitFile() {
            if (this.tgUnit.hasOwnProperty('type_id')) {
                return this.tgUnit.string_data_file;
            }

            return '';
        },
        getTgUnitId() {
            if (this.tgUnit.hasOwnProperty('type_id')) {
                return this.tgUnit.id;
            }

            return 0;
        },
        getTgUnitDataButtons() {
            if (this.tgUnit.hasOwnProperty('type_id')) {
                return this.tgUnit.data.buttons;
            }

            return [];
        },
        getSelectName() {
            return 'tg_units[' + this.index + '][type]';
        },
        getTgUnitName() {
            return 'tg_units[' + this.index + '][id]';
        },
        getTgUnitIsRemoveButtons() {
          if (this.tgUnit.hasOwnProperty('type_id')) {
            return this.tgUnit.data.remove_buttons_after_click;
          }

          return false;
        },
    },
};
</script>

<style lang="sass" scoped>

</style>
