<template>
    <telegram-text-message-component
        :index="index"
        :text="text"
    ></telegram-text-message-component>

    <telegram-buttons-component
        :index="index"
        :items="items"
        :tg-screens="tgScreens"
        :remove-buttons="removeButtons"
    ></telegram-buttons-component>

</template>

<script>
import TelegramTextMessageComponent from './TelegramTextMessageComponent';
import TelegramButtonsComponent from './TelegramButtonsComponent';

export default {
    name: 'TelegramTextButtonMessageComponent',
    components: {
        TelegramTextMessageComponent,
        TelegramButtonsComponent,
    },
    props: {
        index: {
            type: Number,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            default: [],
        },
        tgScreens: {
            type: Object,
            required: true,
        },
        removeButtons: {
            type: Boolean,
            default: false,
        },
    }
}
</script>

<style lang="sass" scoped>

</style>
