<template>
    <!--    <TelegramImageMessageComponent></TelegramImageMessageComponent>-->
    <!--    <TelegramTextMessageComponent></TelegramTextMessageComponent>-->
    <telegram-image-message-component
        :index="index"
        :image="image"
        :image_id="image_id"
        :view="view"
    ></telegram-image-message-component>

    <telegram-text-message-component
        :index="index"
        :text="text"
        :view="view"
    ></telegram-text-message-component>

    <telegram-buttons-component
        :index="index"
        :items="items"
        :tg-screens="tgScreens"
        :remove-buttons="removeButtons"
    ></telegram-buttons-component>

</template>

<script>
import TelegramTextMessageComponent from './TelegramTextMessageComponent';
import TelegramImageMessageComponent from './TelegramImageMessageComponent';
import TelegramButtonsComponent from "./TelegramButtonsComponent.vue";

export default {
    name: 'TelegramImageTextMessageComponent',
    components: {
        TelegramButtonsComponent,
        TelegramImageMessageComponent,
        TelegramTextMessageComponent,
    },
    props: {
        index: {
            type: Number,
            required: true,
        },
        text: {
            type: String,
            default: '',
        },
        image: {
            type: String,
            default: '',
        },
        image_id: {
            type: String,
            default: '',
        },
        view: {
            type: Boolean,
            default: false,
        },
        items: {
            type: Array,
            default: [],
        },
        tgScreens: {
            type: Object,
            required: true,
        },
        removeButtons: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        // console.log(tg_units)
    }
};
</script>

<style lang="sass" scoped>

</style>
